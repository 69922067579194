import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import scss from './FPGAnimator.scss';

let runtimeObj;

class FPGAnimator extends Component {
  componentDidMount() {
    import('./_runtime.js').then(runtime => {
      runtimeObj = runtime;

      runtimeObj.default.init(this, this.props.triggerPoint);
    });
  }
  render() {
    const props = {};

    Object.keys(this.props).forEach(key => {
      if (FPGAnimator.defaultProps && FPGAnimator.defaultProps[key] === undefined) {
        props[key] = this.props[key];
      }
    });

    return React.createElement(
      this.props.tag,
      {
        ...props,
        ref: 'root',
				className: classnames(scss.FPGAnimator, this.props.className),
				id: this.props.id,
        'data-animator': '',
        [`data-${this.props.aniType}`]: '',
      },
      this.props.children,
    );
  }
}

FPGAnimator.propTypes = {
	className: PropTypes.string,
  tag: PropTypes.string.isRequired,
  triggerPoint: PropTypes.number,
	aniType: PropTypes.string,
	onEnter: PropTypes.func,
	onLeave: PropTypes.func,
};

FPGAnimator.defaultProps = {
  className: undefined,
  tag: 'div',
  triggerPoint: 0.9,
	aniType: 'fade-bottom',
	onEnter: ()=>{},
	onLeave: ()=> {},
};

export default FPGAnimator;
