import React,{Component} from 'react';
import scss from './Contact.scss'
import cs from 'classnames'
import Section from '../Section'
import Input from '../../components/Input'
import {RichText} from 'prismic-reactjs'
import axios from 'axios'

import Loading from '../../components/Loading'

let api='/submit/';
if(process.env.NODE_ENV === 'production') {
	api='/submit/';
}

class Contact extends Component {
	constructor (props) {
		super(props);
		this.state={
			showMessage: false,
			data: {

			},
			errors: {
				firstName: '',
				lastName: '',
				email: '',
				message: '',
			},
			loadingShow: false,
			response: {
				title: '',
				description: ''
			},
		}
		this.data={
			firstName: '',
			lastName: '',
			email: '',
			message: '',
		}
		this.validator={
			firstName: {
				requiredMessage: 'first name is required',
			},
			lastName: {
				requiredMessage: 'last name is required',
			},
			email: {
				rule: /^([\w-]\.?)+[\w-]+@[\w-]+(\.[\w-]+)+$/,
				message: 'email address is not correct',
				requiredMessage: 'email is required',
			},
			message: {
				requiredMessage: 'message is required',
			}
		}
		this.refsComp ={}
	}
	submitClick=() => {
		let formData = this.state.data;
		let {errors,loadingShow} = this.state;
		if(loadingShow) return;
		let haserror=false;
		Object.keys(errors).forEach(key => {
			if (!formData[key]) {
				haserror=true;
				return errors[key]=this.validator[key].requiredMessage;
			}
			let rule=this.validator[key].rule;
			if (!rule) return;
			console.log(rule)
			console.log(rule.test(formData[key]))
			if (!rule.test(formData[key])) {
				haserror=true;
				errors[key]=this.validator[key].message;
			}
		})
		if (haserror) {
			this.setState({
				errors,
			})
		} else {
			console.log(api)
			this.setState({
				loadingShow: true,
			})
			axios.post(api,formData)
				.then(res => {
					console.log(res)
					this.setState({
						showMessage: true,
						loadingShow: false,
						response: {
							title: 'Thank You',
							description: 'Look for a confirmation email in your inbox'
						},
					})
				})
				.catch(err => {
					this.setState({
						showMessage: true,
						loadingShow: false,
						response: {
							title: '',
							description: "Oops, something went wrong but we'd still love to hear from you."
						},
					})
					console.log(err);
				})
		}

	}
	closeClick=() => {
		this.data = {}
		this.setState({
			showMessage: false,
			data: this.data
		});
		Object.keys(this.refsComp).forEach(key => {
			this.refsComp[key].clear();
		})
	}
	changeHander=(name,val) => {
		this.data[name]=val;
		this.setState({
			data: this.data
		})
	}
	onFocus=(name) => {
		let errors=this.state.errors;
		errors[name]='';
		this.setState({
			errors,
		})
	}
	render() {
		const {
			title,
			reverse_title,
			mailto_address,
			email_placeholder,
			first_name_placeholder,
			last_name_placeholder,
			mailto_instruction,
			message_icon,
			message_placeholder,
			submit_button_label
		}=this.props;
		const {showMessage,errors,response,loadingShow}=this.state;
		return (
			<Section
				title={title}
				reverse_title={reverse_title}
				nextPage={this.props.nextPage}
				id={this.props.id}
				gotoNextPage={this.props.gotoNextPage}
				getSectionComponentRef={this.props.getSectionComponentRef}
				className={cs(scss.Contact,this.props.className)}>
				<div className={scss.wrap}>
					<div style={{
						display: showMessage? 'block':'none'
					}} className={scss.messagePage}>
						<div className={scss.thankyou}>{response.title}</div>
						<div className={scss.description}>
							{response.description}
						</div>
						<button onClick={this.closeClick} className={cs(scss.button,scss.closeButton)}>
							close
						</button>
					</div>
					<div style={{
						visibility: showMessage? 'hidden':'visible'
					}} className={scss.form}>
						<div className={scss.inputContainer}>
						<Loading show={loadingShow} className={scss.loading} container={scss.loadingContainer} />
							<div className={scss.formItem}>
								<div className={scss.itemInner}>
									<Input
										ref={el => this.refsComp['firstName'] = el}
										onFocus={this.onFocus}
										value={this.state.data.firstName}
										name="firstName"
										onChange={this.changeHander}
										className={cs(scss.firstName,scss.Input,errors.firstName&&scss.errorLine)}
										placeholder={first_name_placeholder&&RichText.asText(first_name_placeholder)} />
									{errors.firstName&&<div className={cs(scss.errorMessage,scss.firstError)}>{errors.firstName}</div>}
								</div>
								<div className={scss.itemInner}>
									<Input
										ref={el => this.refsComp['lastName'] = el}
										name="lastName"
										onFocus={this.onFocus}
										value={this.state.data.lastName}
										onChange={this.changeHander}
										className={cs(scss.Input,errors.lastName&&scss.errorLine)}
										placeholder={last_name_placeholder&&RichText.asText(last_name_placeholder)} />
									{errors.lastName&&<div className={scss.errorMessage}>{errors.lastName}</div>}
								</div>

							</div>
							<div className={scss.formItem}>
								<Input
									ref={el => this.refsComp['email'] = el}
									name="email"
									onFocus={this.onFocus}
									className={cs(scss.EmailInput,errors.email&&scss.errorLine)}
									value={this.state.data.email}
									onChange={this.changeHander}
									placeholder={email_placeholder&&RichText.asText(email_placeholder)}
								/>
								{errors.email&&<div className={scss.errorMessage}>{errors.email}</div>}
							</div>
							<div className={scss.formItem}>
								<Input className={cs(scss.textarea,errors.message&&scss.errorLine)}
									onFocus={this.onFocus}
									ref={el => this.refsComp['message'] = el}
									name="message"
									value={this.state.data.message}
									onChange={this.changeHander}
									placeholder={message_placeholder&&RichText.asText(message_placeholder)}
									tag='textarea' message_icon={message_icon}></Input>
								{errors.message&&<div className={scss.errorMessage}>{errors.message}</div>}
							</div>
						</div>
						<div className={scss.formItem}>
							<button onClick={this.submitClick} className={scss.button}>
								{submit_button_label&&RichText.asText(submit_button_label)}
							</button>
						</div>
						<div className={scss.instruction}>
							{mailto_instruction&&RichText.asText(mailto_instruction)}
						</div>
						<div className={scss.address}>
							<a href={'mailto:' + (mailto_address&&RichText.asText(mailto_address))}>
								{mailto_address&&RichText.asText(mailto_address)}
							</a>
						</div>
					</div>
				</div>
			</Section>
		);
	}
}

export default Contact;
