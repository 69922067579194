import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import Prismic from 'prismic-javascript';
// import Index from './views/Index'
import Nav from './components/Nav'
import Header from './components/Header'
import Section from './views/Section'
import FAQs from './views/FAQs'
import Contact from './views/Contact'
import SingleFooter from './views/SingleFooter'
import MultipleFooter from './views/MultipleFooter'
import './assets/css/resetr.css'
import './assets/scss/v.common.scss'
import './assets/scss/common.css'
import {getPositionTop,getScrollTop} from './util'
import './util/RequestAnimationFrame'
import TWEEN from '@tweenjs/tween.js'
import prismicConfig from './prismic-configuration'
import scss from './app.scss'
import scrollDepth from 'scroll-depth'
import Platform from 'joshua-platform'




// import io from 'socket.io-client'

// const socket = io('http://192.168.1.89:3001');


class App extends React.Component {
	constructor (props) {
		super(props)
		this.state={
			headerData: {},
			navData: {},
			footerData: {},
			SectionsData: [],
			show404: false,
		}
		this.loading=true;
		this.mounted=false;
		this.canPushHistory=true;
		this.cantriggerScrollhandler=true;
	}
	componentWillMount() {
		const apiEndpoint=prismicConfig.apiEndpoint;
		this.browserRouterSupport=!!window.history.replaceState;
		if (!this.browserRouterSupport) {
			if (!window.location.hash) {
				window.location.hash='#/'
			}
		}
		this.loading=true;
		Prismic.api(apiEndpoint,{accessToken: prismicConfig.accessToken}).then(api => {
			this.api=api;
			api.query('').then(({results}) => {
				this.loading=false;
				this.resolveData(results);
			});
		});
	}
	componentDidMount() {
		this.mounted=true;
		this.init();
	}
	tracking=() => {
		scrollDepth({
			minHeight: window.innerHeight,
			// elements: ['#ibm'],
			percentage: true,
			userTiming: true,
			pixelDepth: false,
			nonInteraction: false
		});
	}
	logoTracking=() => {
		const {footerData}=this.state;
		if (footerData.partner) {
			let brandName=footerData.partner[0].brand_name[0].text;
			window.ga('send',{
				hitType: 'event',
				eventCategory: brandName,
				eventAction: 'impression',
				eventLabel: `${brandName} Impressions`
			});
		}
	}
	init() {
		if (!(!this.loading&&this.mounted)) return;
		this.sectionsEl=[];   // section el
		this.positions=[];  // section position
		this.offset=0;
		this.maxScroll=0;    // 最大滚动ScrollTop;
		this.ibm=document.getElementById('ibm');
		this.ibmPositionTop=getPositionTop(this.ibm)
		this.introTitle=document.getElementById('introTitle')
		this.mask=document.getElementById('mask'); // nav mask
		if (window.navigator.userAgent.indexOf('SM')>-1) {
			document.documentElement.classList.add('sanxin')
		}
		this.copyContentContainerElToNav();
		this.memuList=document.querySelectorAll('#menuList  li');
		this.tracking();
		for (let i=1;i<=5;i++) {
			this.sectionsEl.push(document.getElementById('section'+i))
		}
		this.initTitleAndReverseTitleEvent();    // title and reseverTitle event;
		this.navEl=document.getElementById('nav');
		this.getPositions();
		this.restorePosition();
		this.container.setAttribute('visible','');   // 页面可见
		window.addEventListener('scroll',this.scrollHandler);
		window.addEventListener('resize',this.resize);
		window.addEventListener('popstate',(e) => {
			this.canPushHistory=false;
			this.restorePosition();
		})
	}
	initTitleAndReverseTitleEvent=() => {
		this.SectionComponentRefs.forEach(SectionComponent => {
			SectionComponent.initEvent();
		})
	}
	copyContentContainerElToNav=() => {
		if (Platform.isIE) return;
		let cloneNode=this.contentContainer.cloneNode(true);
		cloneNode.classList.add('blur-node');
		this.mask.appendChild(cloneNode);
	}
	restorePosition() {
		// this.canPushHistory = false;
		let {pathname}=window.location;
		pathname=pathname.slice(pathname.lastIndexOf('/')+1);
		let {navData}=this.state;
		let page=navData.section_buttons.findIndex(item => item===pathname);
		if (page===-1) {
			return this.scrollTo(0);
		}
		this.cantriggerScrollhandler=false;
		this.scrollTo(Math.min(this.positions[page]-this.offset+1,this.maxScroll),() => {
			this.cantriggerScrollhandler=true;
		});
	}
	getPositions() {
		this.offset=this.navEl.getBoundingClientRect().height;
		this.ibmPositionTop=getPositionTop(this.ibm);
		this.maxScroll=(document.body.scrollHeight||document.documentElement.scrollHeight)-window.innerHeight;
		this.positions=this.sectionsEl.map(el => {
			return getPositionTop(el)
		})
	}
	resolveData(results,callback) {
		let navData;
		let headerData;
		let footerData;
		results.forEach(item => {
			if (item.type==='nav') {
				navData=item.data;
			} else if (item.type==='header') {
				headerData=item.data;
			} else if (item.type==='footer') {
				footerData=item.data;
			}
		})
		let navLinks=navData.nav_links;
		let len=results.length;
		// eslint-disable-next-line
		let SectionsData=navLinks.map(item => {
			for (let i=0;i<len;i++) {
				let resultItem=results[i];
				if (item.link_to_section.id===resultItem.id) {
					return resultItem;
				}
			}
		})
		navData.section_buttons=SectionsData.map(item => item.data.title[0].text);
		let {pathname}=window.location;
		const section_buttons=navData.section_buttons;
		pathname=pathname.slice(pathname.lastIndexOf('/')+1);
		let show404=(section_buttons.indexOf(pathname)===-1);
		if(pathname === '' || pathname === '/'){
			show404 = false;
		}
		this.setState({
			navData,
			headerData,
			SectionsData,
			footerData,
			show404
		},() => {
			callback&&callback();
		})
		this.init();
	}
	resize=() => {
		this.getPositions();
	}
	checkScrollIndex=() => {
		let bodyScrollTop=getScrollTop();
		let len=this.positions.length
		let scrollTop=bodyScrollTop+this.offset;
		if (bodyScrollTop>=this.maxScroll) {
			return len-1
		}
		for (let i=0;i<len;i++) {
			let positionTop=this.positions[i];
			let nexTop=this.positions[i+1];
			if (scrollTop<this.positions[0]) {
				return -1;
			}
			if (!nexTop) return len-1;
			if ((scrollTop>=positionTop)&&(scrollTop<nexTop)) {
				return i;
			}
		}
	}
	ibmIntersection() {
		clearTimeout(this.ibmIntersectionTimer);
		this.ibmIntersectionTimer=setTimeout(() => {
			this.ibmIntersectionTimer=null;
			let clientTop=this.ibm.getBoundingClientRect().top;
			if ((clientTop<=window.innerHeight)&&(clientTop>=0)) {
				if (!this.ibmTracked) {
					this.ibmTracked=true;
					this.logoTracking();
				}
			} else {
				this.ibmTracked=false;
			}
		},300);
	}
	scrollHandler=() => {
		this.ibmIntersection();
		let index=this.checkScrollIndex();
		let memuList=this.memuList;
		this.mask.style.transform=`translate3d(0,${-getScrollTop()}px,0)`;
		if (this.currentPage===index) return;
		this.currentPage=index;
		if (index===-1) {
			memuList.forEach(li => {
				if (!li.getAttribute('active')) return;
				li.removeAttribute('active')
			})
			if (this.canPushHistory) {
				if (this.browserRouterSupport) {
					window.ga('send','pageview',`/`)
					if (this.cantriggerScrollhandler) {
						window.history.replaceState({},'','./')
					}
				} else {
					window.location.hash=`#/`
				}
			} else {
				this.canPushHistory=true;
			}
		}
		if (index<0) return;

		memuList.forEach(li => {
			if (!li.getAttribute('active')) return;
			li.removeAttribute('active')
		})
		if (!memuList[index]) return;
		memuList[index].setAttribute('active','active')
		if (this.canPushHistory) {
			if (this.browserRouterSupport) {
				window.ga('send','pageview',`/${memuList[index].innerHTML}`)
				if (this.cantriggerScrollhandler) {
					window.history.replaceState({},'',`${memuList[index].innerHTML}`)
				}
			} else {
				window.location.hash=`#/${memuList[index].innerHTML}`
			}
		} else {
			this.canPushHistory=true;
		}
	}
	scrollTo=(pos,callback) => {
		if (typeof pos!=='number') return;
		let position={pos: getScrollTop()}
		let tween=new TWEEN.Tween(position)
			.to({pos: pos},1000)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => {
				window.scrollTo(0,position.pos)
			})
			.onComplete(() => {
				callback&&callback();
			});
		tween.start();
		function animate(time) {
			var id=requestAnimationFrame(animate);
			var result=TWEEN.update(time);
			if (!result) cancelAnimationFrame(id);
		}
		animate();
	}
	gotoNextPage=(page) => {
		this.canPushHistory=true;
		if (!this.positions) return;
		if (typeof page==='number') {
			return this.scrollTo(Math.min(this.positions[page]-this.offset+1,this.maxScroll));
		}
		if (this.currentPage===this.memuList.length) this.currentPage=-1;
		this.scrollTo(Math.min(this.positions[this.currentPage+1]-this.offset+1,this.maxScroll));
	}
	getSectionComponentRef=(ref) => {
		this.SectionComponentRefs.push(ref);
	}
	render() {
		const {SectionsData,footerData,show404}=this.state;
		this.SectionComponentRefs=[];
		let contactData = SectionsData.find(item => item.uid === 'contact');
		return (
			<div className={scss.Index} ref={el => this.container=el} style={{
				margin: '0 auto',
			}}>
				<div style={{
					display: show404? 'block':'none'
				}}
				className={scss.page404}>
					{contactData && <div className={scss.page404Wrap}>
					We're sorry, we seem to have lost this page. Try our homepage or reach out with questions. <a href={'mailto:' + contactData.data.mailto_address[0].text} className={scss.address}>{contactData.data.mailto_address[0].text}</a>
					</div>}
				</div>
				<div style={{
					display: show404? 'none':'block'
				}}>
					<Nav
						headerData={this.state.headerData}
						navData={this.state.navData} gotoNextPage={this.gotoNextPage}></Nav>
					<div style={{width: '100%'}} ref={el => this.contentContainer=el}>
						<Header
							headerData={this.state.headerData}
							gotoNextPage={this.gotoNextPage}
							className={scss.header} />
						{
							!this.loading&&
							<div className='ieFadeOut blur'>
								<div ref={el => this.imageContainer=el}>
									{
										(SectionsData.length>0)&&
										SectionsData.map((item,index) => {
											let nextPage=index+1;
											if (index===SectionsData.length-1) nextPage=0;
											let reverse_title=SectionsData[nextPage].data.title;
											let uid=item.uid.toLowerCase();
											let id='section'+(index+1);
											let props={
												gotoNextPage: this.gotoNextPage,
												nextPage,
												id,
												getSectionComponentRef: this.getSectionComponentRef,
												reverse_title
											}
											if (uid==='faqs') {
												return <FAQs key={item.id}  {...item.data} {...props} />
											} else if (uid==='contact') {
												return <Contact key={item.id}  {...item.data} {...props} />
											} else {
												return <Section mobileTitle={index===0? false:true} key={item.id}	{...props} {...item.data} />
											}
										})
									}
								</div>
								{footerData.footer_version&&(
									footerData.footer_version==='single'?
										<SingleFooter footerData={footerData} />:
										<MultipleFooter footerData={footerData} />
								)}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default App;
