function getPositionTop(el) {
	return getScrollTop() + el.getBoundingClientRect().top;
}

function getScrollTop() {
	return document.body.scrollTop || document.documentElement.scrollTop;
}

function getElRect(el) {
	return el.getBoundingClientRect();
}

function setScrollTop(val) {
	document.body.scrollTop = document.documentElement.scrollTop = val;
}

function addEvent(el,type,fn) {
	if(!el || !type || !fn) return;
	el.addEventListener(type,fn);
	return function() {
		el.removeEventListener(type,fn)
	}
}


function imageLoader(el) {
	return new Promise((resolve,reject) => {
		let imgs = el.getElementsByTagName('img');
		let complete = 0;
		let err = false;
		let len = imgs.length;
		let count = len;
		console.log(count)
		function load() {
			complete ++;
				if(complete === count) {
					if(err) {
						return reject();
					}
					resolve();
					console.log('onload')
				}
		}
		function error() {
			complete ++;
			err = true;
		}
		for(let i = 0;i < len; i ++) {
			let img = imgs[i];
			if(img.src) {
				img.onload = load;
				img.onerror = error;
				if(getComputedStyle(img).display === 'none') {
					count --;
				}
			}else {
				count --;
			}
		}
	});
}


export { getPositionTop,getScrollTop,setScrollTop,imageLoader,getElRect,addEvent };