import React from 'react'
import scss from './Header.scss'
import cs from 'classnames'

import {uid} from 'react-uid'

class Header extends React.Component {
	gotoNextPage = () => {
		this.props.gotoNextPage(0);
	}
	componentDidMount() {
		this.arrowImg.setAttribute('animate-down','')
		setInterval(() => {
			this.arrowImg.removeAttribute('animate-down');
			setTimeout(() => {
				this.arrowImg.setAttribute('animate-down','')
			},20);
		},5000);
	}
	render() {
		const {headerData} = this.props;
		let textNodes = [];
		if(headerData.project_description) {
			textNodes = headerData.project_description[0].text.split(' ').map(str => <span key={uid(str)}>{str} </span>)
		}
		return (
			<div className={cs(scss.header,this.props.className)} style={{
				backgroundImage: (headerData.background_photo && `url(${headerData.background_photo.url})`) || ''
			}}>
				{
					headerData.background_photo && 
					<img className={scss.backgroundImage} src={headerData.background_photo.url} alt={headerData.background_photo.alt}/>
				}
				<div className={scss.wrap}>
					{headerData.project_description && 
						<h1  className={cs(scss.title,'title')}>
							{textNodes}
						</h1>
					}
				</div>
				<div className={cs(scss.arrow,'arrow')} onClick={this.gotoNextPage}>
					<div ref={el => this.arrowImg = el}  className={scss.arrowImg}>
						{headerData.arrow_icon && <img src={headerData.arrow_icon.url} alt={headerData.arrow_icon.alt}/>}
					</div>
				</div>
			</div>
		)
	}
}

Header.defaultProps = {
	headerData: {}
}


export default Header;