import React,{Component} from 'react';
import Platform from 'joshua-platform';
// import cs from 'classnames'

import scss from './Nav.scss'

class Nav extends Component {
	constructor (props) {
		super(props);
		this.state={
			navButtons: [
				'About',
				'Problem',
				'Approach',
				'FAQs',
				'Contact',
			],
			expanded: false,
		}
		this.isClick=false;   // 是否点击过了
	}
	componentDidMount() {
		window.addEventListener('resize',this.resize)
		this.menuList.addEventListener('touchmove',this.stopMenuListTouchMove,{passive: false})
	}
	componentWillUnmount() {
		window.removeEventListener('resize',this.resize)
		this.menuList.removeEventListener('resize',this.stopMenuListTouchMove)
	}
	resize=() => {
		if (window.innerWidth>1024) {
			this.menuList.style.transition='none'
			this.menuList.style.display='block';
			this.nav.removeAttribute('expanded')
			this.setState({
				expanded: false
			})
		}else{
			this.menuList.removeAttribute('style')
		}
	}
	clickPage(index) {
		if (!Platform.hasTouch) {
			this.gotoPage(index);
		}
	}
	expand() {
		this.setState({
			expanded: true
		})
		this.menuList.style.display='block';
		setTimeout(() => {
			this.nav.setAttribute('expanded','expanded')
		},20);
	}
	close() {
		this.nav.removeAttribute('expanded')
		this.setState({
			expanded: false
		})
		setTimeout(() => {
			this.menuList.style.display='none';
		},400);
	}
	gotoPage=(page) => {
		const nav=this.nav;
		if (nav.getAttribute('expanded')) {
			this.close();
		}
		this.props.gotoNextPage(page)
	}
	toggle=() => {
		const nav=this.nav;
		if (nav.getAttribute('expanded')) {
			this.close();
		} else {
			this.expand()
		}
		this.setMenulistTransition();
	}
	setMenulistTransition=() => {
		if (!this.isClick) {
			this.isClick=true;
			this.menuList.style.transition='all .5s'
		}
	}
	stopMenuListTouchMove=(e) => {
		e.preventDefault();
	}
	touchstart=(e) => {
		// if(window.innerWidth <= 1024)
		if (Platform.hasTouch) {
			this.touched=true;
		}
	}
	touchend=(index) => {
		if (Platform.hasTouch) {
			if (this.touched) {
				this.gotoPage(index)
				this.touched=false;
			}
		}
	}
	render() {
		const {navData,headerData}=this.props;
		return (
			<div className={scss.nav} ref={el => this.nav=el}>
				<div id="nav" className={scss.navWrap}>
					<div className={scss.navWrapContainer}>
						<div className={scss.logo}>
							{navData.logo_black&&<span>
								<img className={scss.logoImg} src={navData.logo_black.url} alt={navData.logo_black.alt} />
								<img className={scss.logoMenu} src={navData.logo_white.url} alt={navData.logo_white.alt} />
							</span>}
						</div>

						<ul id="menuList" ref={el => this.menuList=el} className={scss.menuList}>
							{
								navData.section_buttons&&navData.section_buttons.map((label,index) => {
									return <li key={label} onTouchEnd={(e) => {
										this.touchend(index)
									}} onTouchStart={this.touchstart} onClick={() => {this.clickPage(index)}}>{label}</li>
								})
							}
						</ul>
						<div onClick={this.toggle} className={scss.menu}>
							<span>{this.state.expanded? 'Close':'Menu'}</span>
						</div>
					</div>
					<div className={scss.maskContainer}>
						{
							headerData.background_photo&&
							<div className={scss.mask} id="mask">

							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

Nav.defaultProps={
	navData: {},
	headerData: {},
}

export default Nav;
