import React, { Component } from 'react';
import scss from './Input.scss'
import cs from 'classnames'

class Input extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			focused: false
		}
	}
	onChange = (e) => {
		this.setState({
			value: e.target.value
		})
		this.props.onChange && this.props.onChange(this.props.name,e.target.value);
	}
	onFocus = () => {
		this.setState({
			focused: true,
		})
		this.props.onFocus && this.props.onFocus(this.props.name,this.state.value);
	}
	onBlur = () => {
		if(!this.props.value) {
			this.setState({
				focused: false,
			})
		}
		this.props.onBlur && this.props.onBlur();
	}
	clear = () => {
		this.setState({
			value: '',
			focused: false
		})
	}
	render() {
		const {tag,message_icon,value} = this.props;
		return (
			<label className={cs(scss.Input,this.props.className)}>
				<span className={cs(scss.placeholder,this.state.focused?scss.focused: '')}>
					{this.props.placeholder}
					{tag === 'textarea'?<span className={scss['textarea-icon']}>
						{message_icon && <img src={message_icon.url} alt={message_icon.alt}/>}
					</span>:null}
				</span>
				{
					tag === 'textarea'?
					<textarea 
					value={value}
					onChange={this.onChange} 
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					></textarea>:
					<input 
					value={value}
					onChange={this.onChange} 
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					type="text"/>
				}
			</label>
		);
	}
}

Input.defaultProps = {
	value: '',
	onInput: ()=> {},
}

export default Input;
