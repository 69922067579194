import React, { Component } from 'react';
import cs from 'classnames'
import './Loading.css'

class Loading extends Component {
	render() {
		const { show } = this.props;
		return (
			<div style={{display: show?'block': 'none'}} className={cs(this.props.container,'spinnerContainer')}>
				<div  className={cs('spinner',this.props.className)}>
					<div className="rect1"></div>
					<div className="rect2"></div>
					<div className="rect3"></div>
					<div className="rect4"></div>
					<div className="rect5"></div>
				</div>
			</div>
		);
	}
}

Loading.defaultProps = {
	show: true,
}

export default Loading;
