import React,{Component} from 'react';
import scss from './Section.scss'
import cs from 'classnames'

import {RichText} from 'prismic-reactjs'
import prismicConfig from '../../prismic-configuration'
import ScrollMagic from 'scrollmagic'

import { addEvent,getElRect } from '../../util'

const controller = new ScrollMagic.Controller();

class Section extends Component {
	gotoNextPage=() => {
		this.props.gotoNextPage(this.props.nextPage)
	}
	componentDidMount() {
		this.props.getSectionComponentRef && this.props.getSectionComponentRef(this);
	}
	componentWillUnmount() {
		this.resizeDestroy && this.resizeDestroy();
	}
	initEvent = () => {
		if(window.innerWidth <= 540) return;
		let titleRect= getElRect(this.title);
		let reverseTitleRect = getElRect(this.reverseTitle);
		let wrapRect = getElRect(this.wrap);
		let titleDuration = wrapRect.height - titleRect.height;
		let reverseTitleDuration = wrapRect.height - reverseTitleRect.height;
		this.titleScene = new ScrollMagic.Scene({
			triggerElement: this.wrap,
			triggerHook: .5,
			offset: -85,
			duration: titleDuration,
		})
		.addTo(controller);
		this.reverseTitleScene = new ScrollMagic.Scene({
			triggerElement: this.wrap,
			triggerHook: .5,
			offset: -85,
			duration: reverseTitleDuration,
		})
		.addTo(controller);
		this.setPin();
		this.resizeDestroy = addEvent(window,'resize',this.resize)
	}
	setPin() {
		if(!this.pined){
			console.log('setPin')
			const titelH = this.title.clientHeight > this.title.clientWidth ? this.title.clientHeight : this.title.clientWidth;
			const reverseTitleH = this.reverseTitle.clientHeight > this.reverseTitle.clientWidth ? this.reverseTitle.clientHeight : this.reverseTitle.clientWidth;
			console.log(reverseTitleH/2)
			this.pined = true;
			this.titleScene.setPin(this.title,{pushFollowers: false});
			this.titleScene.offset(titelH/2)
			this.reverseTitleScene.setPin(this.reverseTitle,{pushFollowers: false});
			this.reverseTitleScene.offset(reverseTitleH/2)

		}
	}
	removePin() {
		if(this.pined) {
			this.pined = false;
			this.titleScene.removePin(true);
			this.reverseTitleScene.removePin(true);
			this.titleScene.destroy();
			this.reverseTitleScene.destroy();
		}
	}
	resize = () => {
		let titleRect= getElRect(this.title);
		let reverseTitleRect = getElRect(this.reverseTitle);
		let wrapRect = getElRect(this.wrap);
		let titleDuration = wrapRect.height - titleRect.height;
		let reverseTitleDuration = wrapRect.height - reverseTitleRect.height;
		this.titleScene.duration(titleDuration);
		this.reverseTitleScene.duration(reverseTitleDuration);
		if(window.innerWidth <= 540) {
			this.removePin();
		}
	}
	render() {
		const {content,title,reverse_title,updates_link_label,updates_link,updates_link_icon}=this.props;
		return (
			<div id={this.props.id} className={cs(scss.section,this.props.className)}>
				<div className={scss.sectionWrap}>
					<div  className={scss.titleContainer}>
						<div ref={el => this.title = el}
						className={cs(scss.titleP,scss.title,this.props.mobileTitle? '':scss.mobileTitle)}>
							{title&&RichText.asText(title)}
						</div>
						<div
						className={cs(scss.title,scss.titlePlaceholder,this.props.mobileTitle? '':scss.mobileTitle)}>
							{title&&RichText.asText(title)}
						</div>
					</div>
					<div  className={scss.reverseTitleContainer}>
						<div 
						ref={el => this.reverseTitle = el} 
						onClick={this.gotoNextPage}
						className={cs(scss.reverseTitle,scss.reverseTitleP,'reverseTitle')}>
							<span className={scss.before}>⇠ </span>
							{reverse_title&&RichText.asText(reverse_title)}
						</div>
						<div 
						className={cs(scss.reverseTitle,scss.reverseTitlePlaceholder)}>
							<span className={scss.before}>⇠ </span>
							{reverse_title&&RichText.asText(reverse_title)}
						</div>
					</div>
					
					<div ref={el => this.wrap = el} className={cs(scss.wrap)}>
						{
							this.props.children?
								this.props.children:
								<div className={cs(scss.richText,'fade-bottom')}>
									{content&&RichText.render(content,prismicConfig.linkResolver,prismicConfig.htmlSerializer)}
								</div>
						}
						{
							updates_link_label&&updates_link_label[0]&&
							<div className={scss.updateLink}>
								<a href={updates_link.url} target="__blank">{RichText.asText(updates_link_label)}</a>
								<span className={scss.updateLickIcon}>
									<img src={updates_link_icon.url} alt={updates_link_icon.alt} />
								</span>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}
Section.defaultProps={
	mobileTitle: true,
}

export default Section;
