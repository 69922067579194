import React, { Component } from 'react';

import scss from './SingleFooter.scss'
import { RichText } from 'prismic-reactjs'

class SingleFooter extends Component {
	render() {
		const {footerData} = this.props;
		console.log(footerData)
		return (
			<div className={scss.Footer}>
				<div className={scss.logo}>
					<div className={scss.logoText}>{footerData.single_footer && RichText.asText(footerData.single_footer[0].partnership_text)}</div>
					{footerData.partner && <div className={scss.logoImg}>
							<a href={footerData.nyt_logo_link.url} target="__blank">
								<img style={{
									width: footerData.nyt_logo.dimensions.width / 2 / 16 + 'em'
								}} src={footerData.nyt_logo.url} alt={footerData.nyt_logo.alt}/>
							</a>
							<img style={{
								width: 24/16+'em',
								marginLeft: 5/16+'em',
								marginRight: 5/16+'em'
							}} src={require('../../assets/images/line_nyt.png')} alt="line"/>
							{footerData.partner[0].partner_logo_link.url ? <a id="ibm" href={footerData.partner[0].partner_logo_link.url} target="__blank">
								<img style={{
									width: footerData.partner[0].partner_logo.dimensions.width / 2 / 16 + 'em'
								}} src={footerData.partner[0].partner_logo.url} alt={footerData.partner[0].partner_logo.alt}/>
							</a>:<span id="ibm">
								<img style={{
									width: footerData.partner[0].partner_logo.dimensions.width / 2 / 16 + 'em'
								}} src={footerData.partner[0].partner_logo.url} alt={footerData.partner[0].partner_logo.alt}/>
							</span>}
					</div>}
				</div>
				<div className={scss.copyRight}>{footerData.single_footer && RichText.asText(footerData.single_footer[0].copyright)}</div>
			</div>
		);
	}
}

export default SingleFooter;
