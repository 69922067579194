import React, { Component } from 'react';
import scss from './RatioImage.scss'
import cs from 'classnames'
import PropTypes from 'prop-types'


class RatioImage extends Component {
	render() {
		const { ratio } = this.props;
		return (
			<div ref={el => this.root = el} style={{paddingTop: ratio * 100 + '%'}} className={cs(scss.Cover,this.props.className)}>
				{this.props.children}
			</div>
		);
	}
}

RatioImage.propsTypes = {
	ratio: PropTypes.number,
	type: PropTypes.oneOf(['image','video']),
	children: PropTypes.node,
}

RatioImage.defaultProps = {
	ratio: 1,
	type: 'image'
}

export default RatioImage;
