import React, { Component } from 'react';
import {uid} from 'react-uid'
import scss from './MultipleFooter.scss'
import { RichText } from 'prismic-reactjs'
import Platform from 'joshua-platform'

class MultipleFooter extends Component {
	trackingClick = (e) => {
		let brand_name = e.target.dataset.name;
		let action = 'tap';
		if(Platform.isDesktop) {
			action = 'click'
		}
		window.ga('send',{
			hitType: 'event',
			eventCategory: 'Footer Partner Logo',
			eventAction: action,
			eventLabel: `${brand_name}`
		});
	}
	render() {
		const {footerData} = this.props;
		return (
			<div className={scss.MultipleFooter}>
				<div className={scss.leftItem}>
					<div className={scss.logoText}>{footerData.multiple_footer && RichText.asText(footerData.multiple_footer[0].partnership_text)}</div>
					{footerData.partner && <div className={scss.logoImg}>
							<a href={footerData.nyt_logo_link.url} target="__blank">
								<img style={{
									width: footerData.nyt_logo.dimensions.width / 2 / 50 + 'em'
								}} src={footerData.nyt_logo.url} alt={footerData.nyt_logo.alt}/>
							</a>
							<img style={{
								width: 24/50+'em',
								marginLeft: 5/50+'em',
								marginRight: 5/50+'em'
							}} src={require('../../assets/images/line_nyt.png')} alt="line"/>
							{footerData.partner[0].partner_logo_link.url ? <a id="ibm" href={footerData.partner[0].partner_logo_link.url} target="__blank">
								<img style={{
									width: footerData.partner[0].partner_logo.dimensions.width / 2 / 50 + 'em'
								}} src={footerData.partner[0].partner_logo.url} alt={footerData.partner[0].partner_logo.alt}/>
							</a>:<span id="ibm">
								<img style={{
									width: footerData.partner[0].partner_logo.dimensions.width / 2 / 50 + 'em'
								}} src={footerData.partner[0].partner_logo.url} alt={footerData.partner[0].partner_logo.alt}/>
							</span>}
					</div>
					}
					{footerData.partner && <div className={scss.logoImgMobile}>
							<a href={footerData.nyt_logo_link.url} target="__blank">
								<img style={{
									width: footerData.nyt_logo.dimensions.width / 2 / 16 + 'em'
								}} src={footerData.nyt_logo.url} alt={footerData.nyt_logo.alt}/>
							</a>
							<img style={{
								width: 24/16+'em',
								marginLeft: 5/16+'em',
								marginRight: 5/16+'em'
							}} src={require('../../assets/images/line_nyt.png')} alt="line"/>
							{footerData.partner[0].partner_logo_link.url ? <a id="ibm" href={footerData.partner[0].partner_logo_link.url}>
								<img style={{
									width: footerData.partner[0].partner_logo.dimensions.width / 2 / 16 + 'em'
								}} src={footerData.partner[0].partner_logo.url} alt={footerData.partner[0].partner_logo.alt}/>
							</a>:<span id="ibm">
								<img style={{
									width: footerData.partner[0].partner_logo.dimensions.width / 2 / 16 + 'em'
								}} src={footerData.partner[0].partner_logo.url} alt={footerData.partner[0].partner_logo.alt}/>
							</span>}
					</div>
					}
					<div className={scss.copyRight}>
					{footerData.multiple_footer && RichText.asText(footerData.multiple_footer[0].copyright)}
					</div>
				</div>
				<div className={scss.rightItem}>
					{
						footerData.partner_logos && 
						footerData.partner_logos.map(item => {
							return <div key={uid(item)} className={scss.partinerLogo}>
								{
									item.brand_logo_link.url ? <a href={item.brand_logo_link.url}>
										<img onClick={this.trackingClick} data-name={item.brand_name[0].text} src={item.logo.url} style={{
										width: (item.logo.dimensions.width / 2)/50 + 'em'
									}} alt={item.logo.alt} />
									</a>:
									<img onClick={this.trackingClick} data-name={item.brand_name[0].text} src={item.logo.url} style={{
										width: (item.logo.dimensions.width / 2)/50 + 'em'
									}} alt={item.logo.alt} />
								}
								<img className={scss.lineImg} src={require('./line.png')} alt="line" />
							</div>
						})
					}
				</div>
				<div className={scss.mobileItem}>
					<div className={scss.divide}>
						<span>and</span>
					</div>
					<div className={scss.logoMobileContainer}>
					{
						footerData.partner_logos &&
						footerData.partner_logos.map(item => {
							return <div key={uid(item)} className={scss.logoMobileContainerItem}>
								{
									item.brand_logo_link.url ? <a href={item.brand_logo_link.url}>
										<img onClick={this.trackingClick} data-name={item.brand_name[0].text} src={item.logo.url} style={{
										width: (item.logo.dimensions.width / 2)/16 + 'em'
									}} alt={item.logo.alt} />
									</a>:
									<img onClick={this.trackingClick} data-name={item.brand_name[0].text} src={item.logo.url} style={{
										width: (item.logo.dimensions.width / 2)/16 + 'em'
									}} alt={item.logo.alt} />
								}
							</div>
						})
					}
				</div>
				<div className={scss.line}></div>
				<div className={scss.copyRightMobile}>{footerData.multiple_footer && RichText.asText(footerData.multiple_footer[0].copyright)}</div>
				</div>
			</div>
		);
	}
}

export default MultipleFooter;
