import React, { Component } from 'react';
import Section from '../Section'

import scss from './FAQs.scss'
import { RichText } from 'prismic-reactjs'
import {uid} from 'react-uid'

import cs from 'classnames'

class FAQs extends Component {
	render() {
		const {FAQs_list,title,reverse_title} = this.props;
		return (
			<Section 
			title={title}
			reverse_title={reverse_title}
			nextPage={this.props.nextPage}  
			gotoNextPage={this.props.gotoNextPage} 
			id={this.props.id} 
			getSectionComponentRef={this.props.getSectionComponentRef}
			className={scss.FAQs}>
				<div className={cs(scss.wrap,'fade-bottom')}>
					{
						FAQs_list && FAQs_list.length > 0 && 
						FAQs_list.map(item => (
								<div key={uid(item)} className={scss.FAQList}>
									<div className={scss.icon}>
										<img style={{width: item.icon.dimensions.width / 2}} src={item.icon.url} alt={item.icon.alt}/>
									</div>
									<div className={scss.listItem}>
										<h2 className={scss.question}>{RichText.asText(item.question)}</h2>
										<div className={scss.answer}>
											{RichText.render(item.answer)}
										</div>
									</div>
								</div>
						))
					}
				</div>
			</Section>
		);
	}
}

export default FAQs;
